import React, {useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import Menu from "./Menu";
import axios from "axios";
import Cookies from "js-cookie";
import Button from "react-bootstrap/Button";
import {NotificationContainer, NotificationManager} from "react-notifications";

function MatchResult(props) {
  const [hypothesisSubmitted, setHypothesisSubmitted] = React.useState(false);

  const location = useLocation();

  const navigate = useNavigate();

  // const BASE_URL = "https://api.creative-space.nliwod.org/users";
  const BASE_URL = "https://creativespace.leuphana.de/api/users";

  const setHeaders = () => {
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  }

  const handleBackendError = (error_msg) => {
    console.log(error_msg);
    NotificationManager.error(error_msg);
  }

  useEffect(() => {
    const checkHasSubmittedHypotheses = () => {
      try {
        setHeaders();

        axios
          .get(BASE_URL + "/" + Cookies.get("user_id") + "/has_submitted_hypotheses")
          .then((response) => {
            setHypothesisSubmitted(response.data);
          })
          .catch((error) => handleBackendError(error.response.data.msg));

      } catch (error) {
        NotificationManager.error(error.message);
        console.log(error)
      }
    };

    checkHasSubmittedHypotheses()
  }, [])

  const handleSubmitHypothesis = () => {
    navigate("/submit-hypothesis");
  }

  return (
    <div className="App" id="match-result-container">
      <Menu title="Menu"/>
      <div id="match-result">
        <span>Match number {location.state.matchID}</span>
        { location.state.won ? <div id="match-outcome-message">You won!</div>: "" }
        <hr />
        <div id="match-result-own">
          <div>Your term: {location.state.ownTerm}</div>
          <div>Your score: {location.state.ownTermScore.toFixed(4)}</div>
        </div>
        <hr />
        <div id="match-result-opponent">
          <div>{location.state.opponentName}'s term: {location.state.opponentTerm}</div>
          <div>{location.state.opponentName}'s score: {location.state.opponentTermScore.toFixed(4)}</div>
        </div>
      </div>
      <hr />
      <div id="update-or-submit-hypothesis">
        <Button variant="primary" id="update-or-submit-hypothesis" onClick={handleSubmitHypothesis}>
          {hypothesisSubmitted ? "Update hypothesis" : "Submit hypothesis"}
        </Button>
      </div>
      <NotificationContainer />
    </div>
  )
}

export default MatchResult;