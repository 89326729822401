import React, {useEffect, useState} from "react";
import axios from "axios";
import WordCloud from "react-d3-cloud";
import {NotificationContainer, NotificationManager} from "react-notifications";

function HypothesesWordCloud(props) {
  const [wordCounts, setWordCounts] = useState([]);
  const [wordsLoaded, setWordsLoaded] = useState(false);

  const BASE_URL = "https://creativespace.leuphana.de/api/statistics/hypotheses/word_counts";

  const setHeaders = () => {
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  }

  const handleBackendError = (error_msg) => {
    console.log(error_msg);
    NotificationManager.error(error_msg);
  }

  useEffect(() => {
    setHeaders();

    try {
      axios
        .get(BASE_URL)
        .then((response) => {
          const wc = [];
          response.data.forEach(
            map => {
              Object.keys(map).forEach((key) => {
                wc.push({
                  "text": key,
                  "value": map[key]
                })
              });
            }
          );

          setWordCounts(wc);
          setWordsLoaded(true);
        })
        .catch(error => {handleBackendError(error.response.data.msg)});

    } catch (error) {
      console.log(error);
    }

  }, []);

  return (
    <div className="word-cloud-container">
      <h2>Word Cloud</h2>
      {
        wordsLoaded
          ? <WordCloud
              data={wordCounts}
              fontSize={(word) => Math.log2(word.value) * 2}
              padding={1}
              width={100}
              height={50}
          />
          : <div>Loading word cloud</div>
      }
      <NotificationContainer />
    </div>
  )
}

export default HypothesesWordCloud;