import React from 'react';
// import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-notifications/lib/notifications.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import Rules from './components/Rules';
import InitiateMatch from './components/InitiateMatch';
import Match from './components/Match';
import JoinMatch from "./components/JoinMatch";
import AutoJoinMatch from "./components/AutoJoinMatch";
import MatchResult from "./components/MatchResult";
import SubmitHypothesis from "./components/SubmitHypothesis";

// import reportWebVitals from './reportWebVitals';
import { createRoot } from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';
import Dashboard from "./components/Dashboard";
import Feedback from "./components/Feedback";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />
    },
    {
        path: "/rules",
        element: <Rules />
    },
    {
        path: "/initiate-match",
        element: <InitiateMatch />
    },
    {
        path: "/join-match",
        element: <JoinMatch />
    },
    {
        path: "/auto-join-match/:matchID",
        element: <AutoJoinMatch />
    },
    {
        path: "/play/:matchID",
        element: <Match />
    },
    {
        path: "/match-info/:matchID",
        element: <MatchResult />
    },
    {
        path: "/submit-hypothesis",
        element: <SubmitHypothesis />
    },
    {
        path: "/dashboard",
        element: <Dashboard />
    },
    {
        path: "/feedback",
        element: <Feedback />
    }
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
