import React, {useEffect, useState} from 'react';
import axios from "axios";
import Table from "react-bootstrap/Table";
import {NotificationContainer, NotificationManager} from "react-notifications";

function HypothesesList() {
  const [hypothesesList, setHypothesesList] = useState([]);

  const BASE_URL = "https://creativespace.leuphana.de/api/statistics/hypotheses";

  const setHeaders = () => {
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  }

  const handleBackendError = (error_msg) => {
    console.log(error_msg);
    NotificationManager.error(error_msg);
  }

  useEffect(() => {
    try {
      setHeaders();

      axios
        .get(BASE_URL)
        .then((response) => {
          setHypothesesList(response.data);
        })
        .catch((error) => {handleBackendError(error.response.data.msg)});

    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <div className="hypotheses-list-container">
      <h2>Hypotheses</h2>
      <Table striped bordered hover className="hypotheses-list-table">
        <thead>
          <tr>
            <th>Hypothesis</th>
            <th>User</th>
          </tr>
        </thead>
        <tbody>
        {hypothesesList.map((entry, index) => (
          <tr key={index}>
            <td>{entry['hypothesis']}</td>
            <td>{entry['nickname'] ? entry['nickname'] : entry['user_id']}</td>
          </tr>
        ))}
        </tbody>
      </Table>
      <NotificationContainer />
    </div>
  )
}

export default HypothesesList