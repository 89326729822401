import axios from 'axios'

import './App.css';
import React, {useEffect, useState} from "react";
import Cookies from "js-cookie";
import Menu from "./components/Menu";
import ContentEditable from "react-contenteditable";
import {NotificationContainer, NotificationManager} from "react-notifications";

function App() {
  const DEFAULT_COOKIE_DURATION_IN_DAYS = 5;
  const [userID, setUserID] = useState(null);
  const [nickname, setNickname] = useState(null);
  const [groupName, setGroupName] = useState(null);
  const [currentHypothesis, setCurrentHypothesis] = useState(null);

  // const BASE_URL = "https://api.creative-space.nliwod.org/users";
  const BASE_URL = "https://creativespace.leuphana.de/api/users";
  const noHypothesisPlaceholder = "No hypothesis submitted, yet";

  const getCookieParameters = () => {
    return  {
      "sameSite": "None",
      "secure": "true",
      "expires": DEFAULT_COOKIE_DURATION_IN_DAYS
    }
  }

  const setHeaders = () => {
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  }

  const handleBackendError = (error_msg) => {
    console.log(error_msg);
    NotificationManager.error(error_msg);
  }

  const updateNickname = async (enteredNickname) => {
    if ((enteredNickname) && (enteredNickname.trim() !== "")) {
      try {
        const cookieUserID = Cookies.get("user_id");

        setHeaders();
        const url = BASE_URL + "/" + cookieUserID;
        axios
          .put(url, {"nickname": enteredNickname})
          .then((response) => {
            NotificationManager.success('Nickname updated successfully.');
          })
          .catch((error) => {
            handleBackendError(error.response.data.msg);

            if (error.response.data.msg === "Invalid user ID " + userID) {
              Cookies.delete("user_id");
              setUserID(null);
            }
          });

        setNickname(enteredNickname);
        Cookies.set("nickname", enteredNickname, getCookieParameters());

      } catch (error) {
        console.log(error);
      }
    }
  }

  const updateGroupName = async (enteredGroupName) => {
    if ((enteredGroupName) && (enteredGroupName.trim() !== "")) {
      try {
        const cookieUserID = Cookies.get("user_id");

        setHeaders();
        const url = BASE_URL + "/" + cookieUserID;
        axios
          .put(url, {"group_name": enteredGroupName})
          .then((response) => {
            NotificationManager.success('Group updated successfully.');
          })
          .catch((error) => {
            handleBackendError(error.response.data.msg);

            if (error.response.data.msg === "Invalid user ID " + userID) {
              Cookies.delete("user_id");
              setUserID(null);
            }
          });

        setGroupName(enteredGroupName);
        Cookies.set("group_name", enteredGroupName, getCookieParameters());

      } catch (error) {
        console.log(error);
      }
    }
  };

  const updateHypothesis = (enteredHypothesis) => {
    if (enteredHypothesis !== noHypothesisPlaceholder) {

      const tmpUserID = userID ? userID : Cookies.get("user_id");

      try {
        axios
          .post(
            BASE_URL + "/" + tmpUserID + "/hypothesis",
            {"user_id": tmpUserID, "hypothesis": enteredHypothesis})
          .then((response) => {
            console.log(response);
            NotificationManager.success('Hypothesis updated successfully.');
          })
          .catch((error) => handleBackendError(error.response.data.msg));

      } catch (error) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    const getUserID = async () => {
      if (userID == null) {
        const cookieUserID = Cookies.get("user_id");

        if (cookieUserID) {
          setUserID(cookieUserID);

        } else {
          try {
            setHeaders()
            axios
              .post(BASE_URL, {})
              .then((response) => {
                const responseUserID = response.data["user_id"];
                setUserID(responseUserID);
                Cookies.set("user_id", responseUserID, getCookieParameters());
              })
              .catch((error) => handleBackendError(error.response.data.msg));

          } catch (error) {
            console.log(error);
          }
        }
      }
    };

    getUserID();
  }, []);

  useEffect(() => {
    const getNickname = async () => {
      if (nickname == null) {
        const cookieNickname = Cookies.get("nickname");

        if (cookieNickname) {
          setNickname(cookieNickname);

        } else {
          const tmpUserID = userID ? userID : Cookies.get("user_id");

          if (tmpUserID) {
            try {
              const url = BASE_URL + "/" + tmpUserID + "/nickname";

              setHeaders()
              axios
                .get(url)
                .then((response) => {
                  const responseNickname = response.data["nickname"];
                  setNickname(responseNickname);
                  if (responseNickname) {  // to not set it to null in the cookie
                    Cookies.set("nickname", responseNickname, getCookieParameters());
                  }
                })
                .catch((error) => handleBackendError(error.response.data.msg));

            } catch (error) {
              // TODO: handle case: user was deleted in the backend but is still kept in the cookie
              console.log(error);
            }
          }
        }
      }
    };
    getNickname();
  }, [userID]);

  useEffect(() => {
    const getGroupName = async () => {
      if (groupName == null) {
        const cookieGroupName = Cookies.get("group_name");

        if (cookieGroupName) {
          setGroupName(cookieGroupName);

        } else {
          const tmpUserID = userID ? userID : Cookies.get("user_id");

          if (tmpUserID) {
            try {
              const url = BASE_URL + "/" + tmpUserID + "/group_name";

              setHeaders()
              axios
                .get(url)
                .then((response) => {
                  setGroupName(response.data["group_name"]);
                })
                .catch((error) => handleBackendError(error.response.data.msg));

            } catch (error) {
              console.log(error);
            }
          }

          if (groupName == null) {
            setGroupName("No group");
          } else {
            Cookies.set("group_name", groupName, getCookieParameters());
          }
        }
      }
    };
    getGroupName();
  }, [userID]);

  useEffect(() => {
    const tmpUserID = userID ? userID : Cookies.get("user_id");

    if (tmpUserID) {
      try {
        const url = BASE_URL + "/" + tmpUserID + "/hypothesis";

        setHeaders()
        axios
          .get(url)
          .then((response) => {
            if (response.data["text"] == null) {
              setCurrentHypothesis(noHypothesisPlaceholder);
            } else {
              setCurrentHypothesis(response.data["text"]);
            }
          })
          .catch((error) => handleBackendError(error.response.data.msg));

      } catch (error) {
        console.log(error);
      }
    }
  }, [userID]);

  return (
    <div className="App">
      <Menu title="Menu"/>
      <h2>Home</h2>
      <div id="user-info">
        <span>Hello </span>
        <ContentEditable
          html={nickname ? nickname : userID}
          disabled={false}
          onBlur={e => updateNickname(e.target.textContent)}
          tagName={'span'}
          className ="content-editable"
        />
      </div>
      <div id="group-info">
        <span> Group: </span>
        <ContentEditable
          html={groupName}
          disabled={false}
          onBlur={e => updateGroupName(e.target.textContent)}
          tagName={'span'}
          className="content-editable"
        />
      </div>
      <div id="current-hypothesis">
        <span>Current hypothesis: </span>
        <ContentEditable
          html={currentHypothesis}
          disabled={false}
          onBlur={e => updateHypothesis(e.target.textContent)}
          tagName={'span'}
          className="content-editable"
        />
      </div>
      <NotificationContainer />
    </div>
  )
}

export default App;
