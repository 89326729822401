import Menu from "./Menu";
import React from "react";
import GroupRanking from "./GroupRanking";
import MatchHistorgram from "./MatchHistorgram";
import HypothesesWordCloud from "./HypothesesWordCloud";
import HypothesesList from "./HypothesesList";
import Accordion from "react-bootstrap/Accordion";
import GroupScores from "./GroupScores";

function Dashboard() {
  return (
    <div className="App" id="dashboard-container">
      <Menu title='Menu'/>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Group Ranking</Accordion.Header>
          <Accordion.Body>
            <GroupRanking/>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Match Histogram</Accordion.Header>
          <Accordion.Body>
            <MatchHistorgram/>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Hypotheses Word Cloud</Accordion.Header>
          <Accordion.Body>
            <HypothesesWordCloud/>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Group Hypotheses Scores</Accordion.Header>
          <Accordion.Body>
            <GroupScores />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default Dashboard;