import Menu from "./Menu";
import React, {useState} from "react";
import axios from "axios";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {NotificationContainer, NotificationManager} from "react-notifications";

function JoinMatch(props) {
  const [matchID, setMatchID] = useState("");
  const navigate = useNavigate();

  // const BASE_URL = "https://api.creative-space.nliwod.org/matches";
  const BASE_URL = "https://creativespace.leuphana.de/api/matches";

  const setHeaders = () => {
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  }

  const handleBackendError = (error_msg) => {
    console.log(error_msg);
    NotificationManager.error(error_msg);
  }

  const onSubmitHandler = (e) => {
    if (matchID && matchID !== "") {
      e.preventDefault();

      const userID = Cookies.get("user_id");
      setHeaders();

      try {

        axios
          .post(BASE_URL + "/" + matchID + "/join", {"user_id": userID})
          .then((response) => {
            navigate("/play/" + matchID);
          })
          .catch((error) => handleBackendError(error.response.data.msg));
      } catch (error) {
        console.log(error);  // FIXME: Do something meaningful here
      }
    }
  }

  return (
    <div className="App" id="join-match">
      <Menu title="Menu"/>
      <div id="match-input">
        <form onSubmit= {onSubmitHandler}>
          <label htmlFor="match-id">Match number:</label>
          <input
            id="match-id"
            type="text"
            name="match-id"
            value={matchID}
            onChange={(e) => setMatchID(e.target.value)}
          />
          <Button variant="primary" id="join-match-btn" type="submit">Join</Button>
        </form>
      </div>
      <NotificationContainer />
    </div>
  )
}

export default JoinMatch;