import React, {useEffect} from 'react';
import axios from "axios";
import Table from "react-bootstrap/Table";
import {NotificationContainer, NotificationManager} from "react-notifications";

function GroupRanking(props) {
  const [ranking, setRanking] = React.useState([]);

  // const BASE_URL = "https://api.creative-space.nliwod.org/statistics/group_ranking";
  const BASE_URL = "https://creativespace.leuphana.de/api/statistics/group_ranking";

  const setHeaders = () => {
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  }

  const handleBackendError = (error_msg) => {
    console.log(error_msg);
    NotificationManager.error(error_msg);
  }

  useEffect(() => {
    try {
      setHeaders();

      axios
        .get(BASE_URL)
        .then((response) => {
          setRanking(response.data);
        })
        .catch((error) => handleBackendError(error.response.data.msg));

    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <div className="group-ranking-container">
      <h2>Group Ranking</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Position</th>
            <th>Group</th>
            <th>Wins</th>
          </tr>
        </thead>
        <tbody>
          {ranking.map((entry, index) => (
              <tr key={index}>
                <td>{entry.pos}.</td>
                <td>{entry.group_name ? entry.group_name : <i>NA</i>}</td>
                <td>{entry.wins}</td>
              </tr>
          ))}
        </tbody>
      </Table>
      <NotificationContainer />
    </div>
  )
}

export default GroupRanking;