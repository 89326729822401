import Menu from "./Menu";
import React, {useEffect} from "react";
import {NotificationContainer, NotificationManager} from "react-notifications";
import Cookies from "js-cookie";
import axios from "axios";
import Button from "react-bootstrap/Button";

function SubmitHypothesis(props) {
  const [enteredHypothesis, setEnteredHypothesis] = React.useState("");
  const [hypothesisSubmitted, setHypothesisSubmitted] = React.useState(false);

  // const BASE_URL = "https://api.creative-space.nliwod.org/users";
  const BASE_URL = "https://creativespace.leuphana.de/api/users";


  const setHeaders = () => {
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  }

  const handleBackendError = (error_msg) => {
    console.log(error_msg);
    NotificationManager.error(error_msg);
  }

  const onSubmitHandler = (e) => {
    e.preventDefault();

    const user_id = Cookies.get("user_id");

    try {
      axios
        .post(
        BASE_URL + "/" + user_id + "/hypothesis",
        {"user_id": user_id, "hypothesis": enteredHypothesis})
        .then((response) => {
          console.log(response);
          NotificationManager.success('Hypothesis submitted');
        }).catch(error => handleBackendError(error.response.data.msg));

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    const checkHasSubmittedHypotheses = () => {
      try {
        setHeaders();

        axios
          .get(BASE_URL + "/" + Cookies.get("user_id") + "/has_submitted_hypotheses")
          .then((response) => {
            setHypothesisSubmitted(response.data);
          })
          .catch(error => handleBackendError(error.response.data.msg));

      } catch (error) {
        console.log(error)
      }
    };

    checkHasSubmittedHypotheses()
  }, [])

  useEffect(() => {
    if (hypothesisSubmitted) {
      // get last hypothesis
      try {
        const user_id = Cookies.get("user_id");
        setHeaders()

        axios
          .get(BASE_URL + "/" + user_id + "/hypothesis")
          .then((response) => {
            setEnteredHypothesis(response.data.text);
          })
          .catch((error) => handleBackendError(error.response.data.msg))

      } catch (error) {
        console.log(error)
      }
    }
  }, [hypothesisSubmitted]);

  return (
    <div className="App" id="submit-hypothesis-container">
      <Menu title="Menu"/>
      <div id="submit-hypothesis">
        <form onSubmit={onSubmitHandler}>
          <label htmlFor="hypothesis">Hypothesis: </label>
          <input
            id="hypothesis"
            type="text"
            name="hypothesis"
            value={enteredHypothesis}
            onChange={(e) => setEnteredHypothesis(e.target.value)}
          />
          <Button variant="primary" id="submit-hypothesis-btn" type="submit">
            {hypothesisSubmitted ? "Aktualisieren" : "Senden"}
          </Button>
        </form>
      </div>
      <NotificationContainer />
    </div>
  )
}

export default SubmitHypothesis;
