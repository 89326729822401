import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const menuItems = [
    {
        title: "Home",
        link: "/",
        tagId: "nav-home"
    },
    {
        title: "Rules",
        link: "/rules",
        tagId: "nav-rules"
    },
    {
        title: "Initiate match",
        link: "/initiate-match",
        tagId: "nav-initiate-match"
    },
    {
        title: "Join match",
        link: "/join-match",
        tagId: "nav-join-match"
    },
    {
        title: "Submit hypothesis",
        link: "/submit-hypothesis",
        tagId: "nav-submit-hypothesis"
    },
    {
        title: "Feedback",
        link: "/feedback",
        tagId: "nav-feedback"
    }
]

const Menu = (props) => {
    const [navs] = useState(menuItems);

    return (
        <div className="menu-container">
            <Navbar expand="lg">
                <Container>
                    <Navbar.Brand href="/">Intelligence Game</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {navs.map((nav, index) => (
                              <Nav.Link href={nav.link} key={index} id={nav.tagId} className="navs">
                                  {nav.title}
                              </Nav.Link>
                            ))}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default Menu;
