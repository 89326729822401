import React, {useEffect, useRef, useState} from 'react';
import Menu from "./Menu";
import {useNavigate, useParams} from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import socketIOClient from "socket.io-client";
import Button from "react-bootstrap/Button";
import {NotificationContainer, NotificationManager} from "react-notifications";

function Match(props) {
  const {matchID} = useParams();

  const [enteredTerm, setEnteredTerm] = useState("");
  const [waitingForOpponent, setWaitingForOpponent] = useState(false);

  const socketRef = useRef();

  const navigate = useNavigate();

  const wait_topic = "result_waiting";
  const wake_up_topic = "match_outcome";
  // const SOCKET_URL = "wss://api.creative-space.nliwod.org";
  const SOCKET_URL = "wss://creativespace.leuphana.de/";
  // const BASE_URL = "https://api.creative-space.nliwod.org/matches";
  const BASE_URL = "https://creativespace.leuphana.de/api/matches";

  const setHeaders = () => {
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  }

  const handleBackendError = (error_msg) => {
    console.log(error_msg);
    NotificationManager.error(error_msg);
  }

  useEffect(() => {
    socketRef.current = socketIOClient(SOCKET_URL);
  }, []);

  const onSubmitHandler = (e) => {
    if (enteredTerm && enteredTerm !== "") {
      e.preventDefault();

      const userID = Cookies.get("user_id");

      try {
        setHeaders();

        axios
          .post(BASE_URL + "/" + matchID + "/submit", {"user_id": userID, "term": enteredTerm})
          .then((response) => {
            const wsResponse = socketRef.current.emit(wait_topic, {"user_id": userID, "match_id": matchID});
          })
          .catch((error) => handleBackendError(error.response.data.msg));

        setWaitingForOpponent(true);

      } catch (error) {
        console.log(error);  // FIXME: Do something meaningful here
      }
    }
  };

  useEffect(() => {
    socketRef.current.on(wake_up_topic, (data) => {
      console.log(data);

      const _state = {
        "matchID": data["match_id"],
        "ownTerm": data["own_term"],
        "ownTermScore": data["own_score"],
        "opponentName": data["opponent"],
        "opponentTerm": data["opponent_term"],
        "opponentTermScore": data["opponent_score"],
        "won": data["won"]
      }

      socketRef.current.disconnect();
      navigate("/match-info/" + matchID, {state: _state});
    });
  }, [socketRef, matchID]);

  const getPageContent = () => {
    if (!waitingForOpponent) {
      // Still have to enter our term
      return (
        <form onSubmit={onSubmitHandler}>
          <label htmlFor="entered-term">Your term: </label>
          <input
            id="entered-term"
            type="text"
            name="entered-term"
            value={enteredTerm}
            onChange={(e) => setEnteredTerm(e.target.value)}
          />
          <Button variant="primary" id="term-submit-btn" type="submit">Submit</Button>
        </form>
      );

    } else {
      return (
        <div>Waiting for other player...</div>
      );
    }
  }

  return (
    <div className="App" id="create-match">
      <Menu title='Menü'/>
      <div id="match">
        <span>Partie-Nummer {matchID}</span>
        {getPageContent()}
      </div>
      <NotificationContainer />
    </div>
  );
}

export default Match;