import React, {useEffect} from 'react';
import axios from "axios";
import {NotificationManager} from "react-notifications";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";

function GroupScores(props) {
  const [groupScores, setGroupScores] = React.useState([]);

  const BASE_URL = "https://creativespace.leuphana.de/api/statistics/hypotheses/scores";

  const setHeaders = () => {
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  }

  const handleBackendError = (error_msg) => {
    console.log(error_msg);
    NotificationManager.error(error_msg);
  }

  useEffect(() => {
    setHeaders();

    try {
      axios
        .get(BASE_URL)
        .then((response) => {
          setGroupScores(response.data);
          // console.log(response.data);
        })
        .catch((error) => handleBackendError(error.response.data.msg));

    } catch (error) {
      console.log(error)
    }
  }, [])

  const buildGroupUserScoresTbl = (usersData) => {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>User Name (Top 10)</th>
            <th>Hypothesis</th>
            <th>Hypothesis Score</th>
          </tr>
        </thead>
        <tbody>
          {
            usersData.map((user, index) => (
              <tr key={index}>
                <td>{user["user_id"]}</td>
                <td>{user["text"]}</td>
                <td>{user["score"].toFixed(4)}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>

    )

  };

  return (
    <div className="group-score-container">
      <Accordion>
        {
          groupScores.map((group, index) =>
            Object.keys(group).map((groupName) =>
              (
                <Accordion.Item key={index} eventKey={index.toString()}>
                  <Accordion.Header>
                    <span className="group-score-name">
                      Group: {groupName}
                    </span>
                    <span>Score: {typeof group[groupName]["best"] === "string" ? group[groupName]["best"] : group[groupName]["best"].toFixed(4)}</span>
                  </Accordion.Header>
                  <Accordion.Body>{buildGroupUserScoresTbl(group[groupName]["users"])}</Accordion.Body>
                </Accordion.Item>
              )
            )
          )
        }
      </Accordion>

    </div>
  )
}

export default GroupScores;