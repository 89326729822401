import React, {useEffect} from 'react';
import axios from "axios";
import Plotly from 'plotly.js-dist-min'
import {NotificationContainer, NotificationManager} from "react-notifications";

function MatchHistorgram(props) {
  // const BASE_URL = "https://api.creative-space.nliwod.org/statistics/match_dates";
  const BASE_URL = "https://creativespace.leuphana.de/api/statistics/match_dates";
  const START_DATE = "2024-10-01";
  const END_DATE = "2024-10-02";

  const setHeaders = () => {
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  }

  const handleBackendError = (error_msg) => {
    console.log(error_msg);
    NotificationManager.error(error_msg);
  }

  useEffect(() => {
    setHeaders();

    try {

      axios
        .get(BASE_URL)
        .then((response) => {
          const trace = {
            x: response.data,
            nbinsx: 80,
            type: 'histogram',
          };

          const layout = {
            xaxis: {
              autobinx: false,
              rangeslider: {range: [START_DATE, END_DATE]},
              type: 'date'
            },
            width: 1500
          };

          Plotly.newPlot('histogram-plot', [trace], layout);
        })
        .catch((error) => handleBackendError(error.response.data.msg));

    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="histogram-plot-container">
      <h2>Usage Histogram</h2>
      <div id="histogram-plot"/>
      <NotificationContainer />
    </div>
  )
}

export default MatchHistorgram;
