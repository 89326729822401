import React, { useState, useEffect, useRef } from "react";
import Menu from "./Menu";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import QRCode from "react-qr-code";
import socketIOClient from "socket.io-client";
import {NotificationContainer, NotificationManager} from "react-notifications";

function InitiateMatch() {
  const [matchID, setMatchID] = useState(null);
  const [matchJoinURL, setMatchJoinURL] = useState(null);

  const socketRef = useRef();

  const navigate = useNavigate();

  const wait_topic = "player_waiting";
  const wake_up_topic = "start_match";
  // const SOCKET_URL = "wss://api.creative-space.nliwod.org";
  const SOCKET_URL = "wss://creativespace.leuphana.de/";
  // const BASE_URL = "https://api.creative-space.nliwod.org/matches";
  const BASE_URL = "https://creativespace.leuphana.de/api/matches";
  // const JOIN_URL = "https://creative-space.nliwod.org/auto-join-match";
  const JOIN_URL = "https://creativespace.leuphana.de/auto-join-match";

  const setHeaders = () => {
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  }

  const handleBackendError = (error_msg) => {
    console.log(error_msg);
    NotificationManager.error(error_msg);
  }

  useEffect(() => {
    socketRef.current = socketIOClient(SOCKET_URL);
  }, []);

  useEffect(() => {
    const userID = Cookies.get("user_id");

    if (userID) {
      try {
        setHeaders();
        axios
          .post(BASE_URL, {"user_id": userID})
          .then((response) => {
            const responseMatchID = response.data["match_id"];

            setMatchID(responseMatchID);
            setMatchJoinURL(JOIN_URL + "/" + responseMatchID);
            const wsResponse = socketRef.current.emit(wait_topic, {"user_id": userID, "match_id": responseMatchID});
            // console.log(wsResponse);
            document.getElementById("nav-join-match").disabled = true;
          })
          .catch((error) => handleBackendError(error.response.data.msg));

      } catch (error) {
        console.log(error);
      }
    } else {
      navigate("/")
    }
  },[socketRef]);

  useEffect(() => {
    socketRef.current.on(wake_up_topic, (data) => {
      console.log(data);
      navigate("/play/" + matchID);
    });
  }, [socketRef, matchID]);

  if (matchJoinURL) {
    return (
      <div className="App" id="create-match">
        <Menu title='Menu'/>
        <div id="match-info">
          Match number: <span>{matchID}</span>
        </div>
        <div id="match-qr-code">
          <QRCode value={matchJoinURL}/>
          <div>Waiting for other player to join...</div>
        </div>
        <NotificationContainer />
      </div>
    )
  } else {
    return (
      <div className="App" id="create-match">
        <Menu title='Menu'/>
        <div id="match-info">
          <span>Loading...</span>
        </div>
        <NotificationContainer />
      </div>
    )
  }
}

export default InitiateMatch;