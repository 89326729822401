import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import axios from "axios";
import Cookies from "js-cookie";
import {NotificationContainer, NotificationManager} from "react-notifications";

function AutoJoinMatch(props) {
  const {matchID} = useParams();

  const navigate = useNavigate();

  // const BASE_URL = "https://api.creative-space.nliwod.org/matches";
  const BASE_URL = "https://creativespace.leuphana.de/api/matches";

  const setHeaders = () => {
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  };

  const handleBackendError = (error_msg) => {
    NotificationManager.error(error_msg);
    console.log(error_msg);
  }

  useEffect(() => {
    if (matchID && matchID !== "") {

      const userID = Cookies.get("user_id");

      try {
        setHeaders();

        axios
          .post(BASE_URL + "/" + matchID + "/join", {"user_id": userID})
          .then((response) => {
            navigate("/play/" + matchID);
          })
          .catch((error) => handleBackendError(error.response.data.msg));

      } catch (error) {
        console.log(error);  // FIXME: Do something meaningful here
      }
    }
  }, [matchID]);

  return (
    <div>
      <div>Loading...</div>
      <NotificationContainer />
    </div>
  );
}

export default AutoJoinMatch;