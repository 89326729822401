import React from 'react';
import Menu from "./Menu";

function Feedback() {
  return (
    <div className="App" id="feedback-container">
      <Menu title="Menu"/>
      <div className="feedback">
        <p>If you have any feedback, bug reports or issues, please write us via <a href="mailto:cs-intelligence-game@gmx.de">cs-intelligence-game@gmx.de</a>.</p>
        <p>We appreciate your support!</p>
      </div>
    </div>
  )
}

export default Feedback;