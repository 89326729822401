import React  from "react";
import Menu from "./Menu";

function Rules(props) {
  return (
    <div className="App" id="rules">
      <Menu title="Menu"/>
      <h2>Rules</h2>
      <div>
        <p>The primary purpose of this game is to meet with fellow students, analyze match outcomes, and formulate hypotheses about the underlying scoring mechanisms for grading your input. Hence, you shall investigate the game-inherent rules that make one person win and the other lose.</p>
        <p>To formulate a hypothesis, you may, and in fact, should discuss with your group members and people from other groups to take into account a broad range of experiences and gained knowledge. Hypotheses shall be submitted in English and may be phrases or whole sentences.</p>
      </div>
    </div>
  )
}

export default Rules;